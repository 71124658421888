














import { defineComponent } from '@vue/composition-api'
import router from '@/pages/router'
import { flushToken } from '@/helpers/token-helper'

const loginTryAgain = () => {
    flushToken()
    if (router.currentRoute.path === '/login') {
        window.location.reload()
    } else {
        router
            .push('/login')
            .then(() => router.go(0))
            .catch()
    }
}

export default defineComponent({
    name: 'RedirectionError',
    props: {
        errors: {
            type: Array,
            default: (): string[] => [],
        },
    },
    setup() {
        return {
            loginTryAgain,
        }
    },
})
