





import axios from '@/plugins/axios'
import { defineComponent, ref } from '@vue/composition-api'
import { AuthorizeTokenResponse, getRedirectPath, initializeAccessToken } from '@/helpers/token-helper'
import { getUser, redirectTo, setUserDetails } from '@/plugins/User'
import router from '@/pages/router'
import RedirectionLoader from '@/pages/Auth/RedirectionLoader.vue'
import RedirectionError from '@/pages/Auth/RedirectionError.vue'

type QueryObject = {
    [key: string]: string
}

export default defineComponent({
    components: {
        RedirectionLoader,
        RedirectionError,
    },
    setup() {
        const parseQuery = (queryString: string) => {
            const query = {} as QueryObject
            const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
            for (let i = 0; i < pairs.length; i++) {
                const pair = pairs[i].split('=')
                const key = decodeURIComponent(pair[0])
                query[key] = decodeURIComponent(pair[1] || '')
            }
            return query as unknown as AuthorizeTokenResponse
        }

        const errors = ref([] as string[])

        if (localStorage.getItem('jaro_auth')) {
            getUser().then((response) => {
                if (response) {
                    redirectTo(response.type)
                } else {
                    router
                        .push('/dashboard')
                        .then(() => router.go(0))
                        .catch()
                }
            })
        } else {
            const state = localStorage.getItem('auth_state') ?? ''
            const verifier = localStorage.getItem('auth_verifier') ?? ''
            localStorage.removeItem('auth_state')
            localStorage.removeItem('auth_verifier')

            const redirectPath = getRedirectPath()
            const query = parseQuery(window.location.search) as AuthorizeTokenResponse

            if (query.state !== state) {
                errors.value.push('Auth state has changed, please try again.')
            }

            if (errors.value.length === 0) {
                initializeAccessToken(query.code, verifier).then((result) => {
                    if (result === 'success') {
                        axios
                            .get('/v1/user-v2')
                            .then((response) => {
                                if (response && response.data) {
                                    if (response.data.user) {
                                        setUserDetails(response.data)
                                        if (redirectPath) {
                                            router
                                                .push(redirectPath)
                                                .then(() => router.go(0))
                                                .catch()
                                        } else {
                                            redirectTo(response.data.type)
                                        }
                                    } else {
                                        errors.value.push(response.data.message)
                                    }
                                }
                            })
                            .catch(() => {
                                errors.value.push('An error occurred, please try again.')
                            })
                    } else {
                        errors.value.push(result)
                    }
                })
            }
        }

        return {
            errors,
        }
    },
})
