










import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    name: 'RedirectionLoader',
    props: {
        destination: {
            type: String,
            default: 'JaroDesk',
        },
    },
})
